<template>
  <div>
    <v-row class="text-center">
      <div class="bold blue--text text-center mx-4 animate__animated animate__zoomIn"  style="width:100%;"> 
          <p class="text-center heading px-2"  style="width:100%;">
            Affordable Plots Within Diani
          </p>
      </div>
      
      <div class="d-flex justify-center" style="width:100%;"> 
          <p class="text-center px-3 sub-heading">
            We bring to you a varierty of affordable and carefully chosen plots for sale in Diani, South Coast with ready title deeds.
          </p>
        </div>
    </v-row>
  </div>
</template>
<script>
  export default{
    name: 'aboutCard',
    data(){
      return{
        isVisible: false
      }
    },
    methods:{
      visibilityChanged (isVisible, entry) {
        this.isVisible = isVisible
        console.log('entry')
        console.log(entry)
        console.log('entry')
      },
      buyNow(){
        let url = "https://wa.me/+254720244744?text=I%20want%20to%20buy%20this%20land%20now!"
        window.open(url,'_blank')
      }
    }
  }
</script>