<template>
  <div class="pale-blue">
    <div class="pt-4 d-flex justify-center animate__animated animate__zoomIn blue--text heading">
      Contact Information
    </div>
    <v-row class="no-gutters px-3">
      <h4 class="text-center"> Get a hold of us on through these channels</h4>
    </v-row>
    <v-row class="no-gutters d-flex justify-center">
      <v-col class="col-1 d-flex justify-center">
        <a  href="tel: +254720244744">
          <v-icon small class="mx-2 blue--text">
            mdi-phone
          </v-icon>
        </a>
      </v-col>
      <v-col class="col-11">
        <a  href="tel: +254720244744">
          <span>
            0720 244 744
          </span>
        </a>
      </v-col>
    </v-row>
    <v-row class="no-gutters d-flex justify-center">
      <v-col class="col-1 d-flex justify-center">
        <a href="sms: 0720244744">
          <v-icon small class="mx-2 blue--text">
            mdi-message
          </v-icon>
        </a>
      </v-col>
      <v-col class="col-11">
        <a href="sms: 0720244744">
          <span>
            0720 244 744
          </span>
        </a>
      </v-col>
    </v-row>
    <v-row class="no-gutters d-flex justify-center">
      <v-col class="col-1 d-flex justify-center">
        <v-icon small class="mx-2 black--text" @click="goWhatsapp">
          mdi-whatsapp
        </v-icon>
      </v-col>
      <v-col class="col-11">
        <span @click="goWhatsapp">
          0720 244 744
        </span>
      </v-col>
    </v-row>
    <v-row class="no-gutters">
      <v-col class="col-1 d-flex justify-center">
        <a href="mailto: 'Info@adilirealestate.com'">
          <v-icon small class="mx-2 green--text">
            mdi-email
          </v-icon>
        </a>
      </v-col>
      <v-col class="col-11">
      <a href="mailto: 'Info@adilirealestate.com'">
        <span>
          info@adilirealestate.com
        </span>
      </a>
      </v-col>
    </v-row>
    <v-row class="no-gutters d-flex justify-center">
      <v-col class="col-1 d-flex justify-center">
        <a href="https://www.facebook.com/AdiliRealEstate/" target="_blank">
          <v-icon small class="mx-2 blue--text">
            mdi-facebook
          </v-icon>
        </a>
      </v-col>
      <v-col class="col-11">
        <a href="https://www.facebook.com/AdiliRealEstate/" target="_blank">
          <span>
            Adili Real Estate
          </span>
        </a>
      </v-col>
    </v-row>
    <v-row class="no-gutters d-flex justify-center">
      <v-col class="col-1 d-flex justify-center">
        <a href="https://www.twitter.com/AdiliRealEstate/" target="_blank">
          <v-icon small class="mx-2 blue--text">
            mdi-twitter
          </v-icon>
        </a>
      </v-col>
      <v-col class="col-11">
        <a href="https://www.twitter.com/AdiliRealEstate/" target="_blank">
          <span>
            Adili Real Estate
          </span>
        </a>
      </v-col>
    </v-row>
    <v-row class="no-gutters mb-3">
      <v-col class="col-12 d-flex justify-center">
        <v-form ref="form" class="" style="width: 90%;"
          v-model="valid"
          :lazy-validation="lazy">
          <div class="d-flex justify-center animate__animated animate__zoomIn mb-4 sub-heading">
            Quick Message
          </div>
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="name"
          v-model="contact_data.name"
          label="name"
          type="name"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="email"
          v-model="contact_data.email"
          label="email"
          type="name"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="phone number"
          v-model="contact_data.phone_number"
          label="phone number"
          type="number"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="subject"
          v-model="contact_data.subject"
          label="subject"
          type="name"
          outlined
          clearable />
          <v-textarea
          :rules="rules.requiredRule"
          placeholder="message"
          v-model="contact_data.message"
          label="message"
          type="name"
          outlined
          clearable />
          <p class="text-center">
            {{"We will get back to you via email or SMS in the shortest time, typicaly within a few hours"}}
          </p>
          <div  class="d-flex justify-end">
            <v-btn class="success" @click="enquire" :loading="enquire_loading" :disabled="!valid"> send </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  export default{
    name: 'contatUs',
    data(){
      return{
        contact_data: {
          subject: '#Diani Crystal Garden'
        },
        enquire_loading: false,
        book_data: {},
        book_loading: false,
        valid: true,
        lazy: false,
        rules: {
          requiredRule: [
            v => !!v || 'This field is required!',
          ]
        } 
      }
    },
    methods: {
      ...mapActions(['toogleAlertBox']),
      goWhatsapp(){
        let url = "https://wa.me/+254720244744?text=I'm%20interested%20in%20your%20land%20for%20sale"
        window.open(url,'_blank')
      },
      async enquire(){
        try{
          console.log(this.contact_data)
          this.enquire_loading = true
          const response = await
          axios.post(process.env.VUE_APP_API + '/api/enquire', this.contact_data).then((response) => {
            console.log(response)
            const alert_box_info = {
              status: true,
              information: 'Enquiry recorded successfully, we shall get in touch as soon as possible.',
              code: 'success'
            }
            this.toogleAlertBox(alert_box_info)
            this.contact_data = {
              subject: '#Diani Crystal Garden'
            }
            this.enquire_loading = false
          })
          console.log(response)
        } catch(e){
          console.log(e)
        }
      },
      async book(){
        try{
          console.log(this.book_data)
          this.book_loading = true
          const response = await
          axios.post('https://api.adilirealestate.com/api/book', this.book_data).then((response) => {
            console.log(response)
            const alert_box_info = {
              status: true,
              information: 'Site visit recorded successfully, we shall get in touch as soon as possible.',
              code: 'success'
            }
            this.toogleAlertBox(alert_box_info)
            this.book_data = {}
            this.book_loading = false
          })
          console.log(response)
        } catch(e){
          console.log(e)
        }
      }
    }
  }
</script>
<style scoped>
  a{
    text-decoration: none;
    color: black;
  }
</style>
